import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { queryListings } from '../LandingPage/LandingPage.duck';

export const loadData = (params, search, config) => dispatch => {
  const pageId = params.pageId;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, hasFallbackContent)),
    dispatch(queryListings('featured', config)),
  ]);
};
