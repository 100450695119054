import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconExclamationMark.module.css';

const IconExclamationMark = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 18 18" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12.6364V9M9 6.09091V6.08364M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke-width="2" stroke-linecap="round"/>
    </svg>
  );
};

IconExclamationMark.defaultProps = { className: null };

IconExclamationMark.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconExclamationMark;
